import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import img1 from "../asset/images/aim.png";
import img2 from "../asset/images/mission.png";
import img3 from "../asset/images/vision.png";
import star from "../asset/images/home/star.png";

const AboutCard = () => {
  return (
    <div>
      <Box py={5}>
        <Grid container justifyContent="space-between" pt={2}>
          <Grid item lg={6} md={7} xs={12}>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: 40, md: 32, xs: 20 },
                fontWeight: "bold",
              }}
              py={3}
            >
              Our Aim
            </Typography>
            <Box sx={{ display: "flex", alignItems: "start" }}>
              <Box component="img" src={star} width={"50px"} />
              <Typography
                sx={{
                  color: "grey",
                  textAlign: "justify",
                  fontSize: { lg: 22, md: 20, xs: 18 },
                }}
              >
                Here at Klues, our primary goal is to bring together exceptional
                talent and forward-thinking companies to form dynamic
                partnerships that drive success. Our passion is to transform the
                recruitment industry through customized staffing solutions that
                cater to the ever-changing requirements of both candidates and
                clients.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={5} md={5} xs={12}>
            <Box
              component="img"
              alt=""
              src={img1}
              width="100%"
              sx={{
                paddingLeft: { lg: 0, md: 3, xs: 0 },
                pt: { md: 0, xs: 3 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box py={5}>
        <Grid container justifyContent="space-between" pt={2}>
          <Grid item lg={5} md={5} xs={12}>
            <Box
              component="img"
              alt=""
              src={img2}
              width="100%"
              sx={{
                paddingLeft: { lg: 0, md: 3, xs: 0 },
                pt: { md: 0, xs: 3 },
              }}
            />
          </Grid>
          <Grid item lg={6} md={7} xs={12}>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: 40, md: 32, xs: 20 },
                fontWeight: "bold",
              }}
              py={3}
            >
              Our Mission
            </Typography>
            <Box sx={{ display: "flex", alignItems: "start" }}>
              <Box component="img" src={star} width={"50px"} p={1} />
              <Typography
                sx={{
                  color: "grey",
                  textAlign: "justify",
                  fontSize: { lg: 22, md: 20, xs: 18 },
                }}
              >
                At Klues, our purpose is to help companies succeed by providing
                them with exceptional talent that perfectly fits their goals and
                values. By upholding principles of honesty, creativity, and
                individualized support, we strive to revolutionize the hiring
                process and guarantee absolute satisfaction for everyone
                involved. At Klues, we're not simply filling roles; we're
                fostering long-lasting connections that drive both companies and
                individuals towards success.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box py={5}>
        <Grid container justifyContent="space-between" pt={2} pb={3}>
          <Grid item lg={6} md={7} xs={12}>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: 40, md: 32, xs: 20 },
                fontWeight: "bold",
              }}
              py={3}
            >
              Our Vision
            </Typography>
            <Box sx={{ display: "flex", alignItems: "start" }}>
              <Box component="img" src={star} width={"50px"} />
              <Typography
                sx={{
                  color: "grey",
                  textAlign: "justify",
                  fontSize: { lg: 22, md: 20, xs: 18 },
                }}
              >
                Empowering individuals to reach their fullest potential, Klues
                envisions a future where every candidate is equipped with the
                skills and knowledge to thrive in their chosen field. Through
                ingenious training programs and specialized recruitment
                solutions, we aspire to bridge the gap between talent and
                opportunity, rearing a world where success knows no bounds.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={5} md={5} xs={12}>
            <Box
              component="img"
              alt=""
              src={img3}
              width="100%"
              sx={{
                paddingLeft: { lg: 0, md: 3, xs: 0 },
                pt: { md: 0, xs: 3 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AboutCard;
