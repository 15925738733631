import React from "react";
import {
  Typography,
  Grid,
  Input,
  Card,
  Box,
  Button,
  FormLabel,
  FormGroup,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import referImg from "../asset/images/formRefer.png";

const ReferForm = () => {
  const aboutUs = [
    {
      value: "Hr",
      label: "Hr",
    },
    {
      value: "Finance",
      label: "Finance",
    },
    {
      value: "Technology",
      label: "Technology",
    },
    {
      value: "Sales Marketing",
      label: "Sales Marketing",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  return (
    <div>
      <Grid container spacing={5}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={5}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <img src={referImg} alt="" style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <Typography color={"#686565"} width="88%">
                Empower your network by sharing career opportunities. Refer your
                friends to unlock exciting prospects and contribute to shaping
                success stories with us.​
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card
            sx={{
              border: "1px solid #DD952B",
              padding: "30px",
              borderRadius: "26px",
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  sm: "flex",
                  lg: "flex",
                },
                justifyContent: "space-between",
              }}
              py={5}
            >
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  Referral First Name
                </FormLabel>
                <Input
                  placeholder="e.g. James"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                  color="secondary"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  Referral Last Name
                </FormLabel>
                <Input
                  placeholder="e.g. Anderson"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                  color="secondary"
                />
              </FormGroup>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  sm: "flex",
                  lg: "flex",
                },
                justifyContent: "space-between",
              }}
              pb={5}
            >
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  Referral E-Mail
                </FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. James@gmail.com"
                  sx={{
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                ></Input>
              </FormGroup>
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  {" "}
                  Referral Phone
                </FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. 087482445"
                  sx={{
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                ></Input>
              </FormGroup>
            </Box>
            <FormLabel style={{ color: "black" }}>
              Referral Department
            </FormLabel>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "60%" },
              }}
              py={2}
              noValidate
              autoComplete="off"
            >
              <TextField
                select
                defaultValue="EUR"
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                color="secondary"
              >
                {aboutUs.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>

            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  sm: "flex",
                  lg: "flex",
                },
                justifyContent: "space-between",
              }}
              py={4}
            >
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  Your First Name
                </FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. James"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                ></Input>
              </FormGroup>
              <FormGroup>
                <FormLabel style={{ color: "black" }}>Your Last Name</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. Anderson"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                ></Input>
              </FormGroup>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  sm: "flex",
                  lg: "flex",
                },
                justifyContent: "space-between",
              }}
            >
              <FormGroup>
                <FormLabel style={{ color: "black" }}>Your E-Mail</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. James@gmail.com"
                  sx={{
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                ></Input>
              </FormGroup>
              <FormGroup>
                <FormLabel style={{ color: "black" }}>Your Phone</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. 087482445"
                  sx={{
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                ></Input>
              </FormGroup>
            </Box>
            <Box pb={3} pt={5}>
              <Button
                sx={{
                  border: "1px solid #DD952B",
                  borderRadius: "10px",
                }}
                endIcon={<TrendingFlatIcon />}
                variant="outlined"
                color="secondary"
                size="large"
              >
                Send
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReferForm;
