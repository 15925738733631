import sideImg from "../asset/images/home/tabImg.png";
import star from "../asset/images/home/star.png";

export const KluesBringData = [
  {
    img: sideImg,
    StarImg: star,
    tabs: ["A", "B", "C", "D"],

    tabContent: [
      {
        title: "Approach",
        contents: [
          {
            Text1:"Personalised approach for recruitment strategies and training programs to align with unique needs and objectives.",
          },
          {
            Text1:"Thorough assessments to understand the specific requirements and challenges.",
          },
          {
            Text1:"Valuable insights and guidance to clients, enabling them to make informed decisions.",
          },
          {
            Text1:"Committed to ongoing evaluation and enhancement of our services to ensure relevance and effectiveness.",
          },
        ],
      },

      {
        title: "Benchmark",
        contents: [
          {
            Text1:"Innovative talent acquisition strategies to attract top talent and training modules to enhance workforce capabilities.",
          },
          {
            Text1:"Mapping out career pathways for individuals.",
          },
          {
            Text1:"Comprehensive resource hub to empower clients.",
          },
          {
            Text1:"Sustains growth, recognition, and advancement, nurturing top talent within organizations.",
          },
        ],
      },
      {
        title: "Customer Centric",
        contents: [
          {
            Text1:"Creative recruitment campaigns to attract top talent.",
          },
          {
            Text1:"Dynamic workshops focused on career development and advancemen.",
          },
          {
            Text1:"Creative training methodologies and interactive learning experiences.",
          },
          {
            Text1:"Actionable insights and strategies for professional growth.",
          },
        ],
      },
      {
        title: "Detailed",
        contents: [
          {
            Text1:"Recruitment offerings tailored to address specific hiring needs.",
          },
          {
            Text1:"Detailed training programs designed to equip individuals with in-depth knowledge.",
          },
          {
            Text1:"Wealth of industry-specific content, tools, and guides to support ongoing learning and professional development.",
          },
          {
            Text1:"Assessment procedures ensure a comprehensive evaluation of candidates' competencies and qualifications, including certification.",
          },
        ],
      },
    ],
  },
];
