import React from "react";
import Layout from "../component/Layout";
import SubHeader from "../component/SubHeader";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import { Discover } from "../data/SubHeaderData";
import bottamTalent from "../asset/images/bottamBnrImgs/bottamTalent.png";
import WhyKlueCard from "../component/WhyKlueCard";
import TalentCard from "../component/TalentCard";

const TalentAcquisition = () => {
  return (
    <Layout>
      <Container
        sx={{ maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Box sx={{ mt:15}}>
          <SubHeader data={Discover} />
        </Box>

        <Box py={5}>
          <TalentCard />
        </Box>

        <Box py={5}>
          <WhyKlueCard />
        </Box>

        <Box sx={{ textAlign: "center" }} py={5}>
          <img src={bottamTalent} width="100%" />
        </Box>
      </Container>
    </Layout>
  );
};
export default TalentAcquisition;
