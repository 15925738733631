import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import star from "../asset/images//home/star.png";
import { valueData } from "../data/KluesPerksData";

const ValueCard = () => {
  return (
    <div>
      <Box>
        <Typography
          sx={{
            fontSize: { lg: 40, md: 32, xs: 20 },
            fontWeight: "bold",
            pb: 1,
          }}
        >
          The Klues Perks
        </Typography>
        <Typography sx={{ fontSize: { md: 16, xs: 14 }, pb: 3 }} color="grey">
          Uncover the Narrative of Our Endeavor, Fostering Career Advancement
          and Collaborative Success.
        </Typography>
      </Box>

      <Grid container  spacing={4}>
        {valueData.map((data, index) => (
          <Grid item lg={4} md={6} xs={12} key={index}>
            <Card component="Paper" elevation={0}>
              <CardContent sx={{ textAlign: "justify",px:2   }} >
                <Box component="img" src={data.img} />
                <Typography
                  color="primary.blue"
                  sx={{
                    fontSize: { lg: 30, md: 26, xs: 22 },
                    fontWeight: "500",
                  }}
                >
                  {data.title}     
                </Typography>
                <Box display="flex" alignContent="flex-start">
                  <Box alignItems="start" pr={1}>
                    <img src={star} width="30px" />
                  </Box>

                  <Typography
                    component="p"
                    sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
                  >
                    {data.des}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ValueCard;
