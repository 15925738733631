 export const Servicesdata=[
          { 
                    id:"1",
                    title:"Executive Search"
          },
          { 
                    id:"2",
                    title:"Mass Hiring"
          },
          { 
                    id:"3",
                    title:"Talent Identification"
          },
          { 
                    id:"4",
                    title:"Customized Solutions"
          },
        
]
export const trainingData=[
          { 
                    id:"1",
                    title:"Technical Skill Development"
          },
          { 
                    id:"2",
                    title:"Soft Skill Enhancement"
          },
          { 
                    id:"3",
                    title:"Upskilling Programs"
          },
          { 
                    id:"4",
                    title:"Industry-Specific Training Modules"
          },
         ]