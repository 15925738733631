import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { whykluedata } from "../data/WhyKluesData";

const WhyKlueCard = () => {
  return (
    <>
      <Box sx={{pb:5}}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ fontSize: { lg: 20, xs: 18 }, paddingRight: "6px" }}
            color="secondary"
          >
            Valuable InSights
          </Typography>
          <EastOutlinedIcon color="secondary" />
        </Box>

        <Typography
          sx={{
            fontSize: { lg: 40, md: 32, xs: 20 },
            fontWeight: "bold",
            pb: 1,
          }}
        >
          Why Klues
        </Typography>
      </Box>
      <Grid
        container
        rowSpacing={8}
        columnSpacing={5}
        justifyContent={"space-between"}
      >
        {whykluedata &&
          whykluedata.map((item, index) => (
            <Grid item lg={3} xs={6} sm={6} md={6} key={index}>
              <Box sx={{ textAlign: "justify" }}>
                <img src={item.image} />
                <Typography variant="h5" color="grey" fontWeight="bold" pt={1}>
                  {item.id}
                </Typography>
                <Typography
                  sx={{ fontSize: { lg: 18, xs: 16 }, fontWeight: "bold" }}
                  color="#4672E3"
                  py={1}
                >
                  {item.text}
                </Typography>
                <Typography sx={{ fontSize: { lg: 16, xs: 14 } }} color="grey">
                  {item.content}
                </Typography>
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default WhyKlueCard;
