import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import TalentAcquisition from './pages/TalentAcquisition';
import SkillBuilding from './pages/SkillBuilding';
import BrowseJobs from './pages/BrowseJobs';
import SubmityourCV from './pages/SubmityourCV';
import ReferyourFriend from './pages/ReferyourFriend';
import Pagenotfound from './pages/Pagenotfound';
import AboutUs from './pages/AboutUs';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import { useEffect } from 'react';


function App() {

  return (
   <>
   <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/recruitment-services" element={<TalentAcquisition/>} />
    <Route path="/training-services" element={<SkillBuilding/>} />
    <Route path="/browse-job" element={<BrowseJobs/>} />
    <Route path="/submit-cv" element={<SubmityourCV/>} />
    <Route path="/refer-friend" element={<ReferyourFriend/>} />
    <Route path="/about-us" element={<AboutUs/>} />
    <Route path="/terms-conditions" element={<Terms/>} />
    <Route path="/privacy-policy" element={<Privacy/>} />
    <Route path="*" element={<Pagenotfound/>} />
   </Routes>
   </>
  );
}

export default App;
