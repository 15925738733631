import React from "react";
import {
  Typography,
  Grid,
  Input,
  Card,
  Box,
  Button,
  FormLabel,
  FormGroup,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import cvimg from "../asset/images/formCv.png";
const aboutUs = [
  {
    value: "Hr",
    label: "Hr",
  },
  {
    value: "Finance",
    label: "Finance",
  },
  {
    value: "Technology",
    label: "Technology",
  },
  {
    value: "Sales Marketing",
    label: "Sales Marketing",
  },
  {
    value: "Other",
    label: "Other",
  },
];
const CvForm = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={5}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <img src={cvimg} alt="" style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <Typography color={"#686565"} width="88%">
                Ready to unlock new career possibilities? Submit your CV today
                and let us connect you with exciting opportunities tailored to
                your expertise and aspirations. Your next career move starts
                here!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card
            sx={{
              border: "1px solid #DD952B",
              padding: "30px",
              borderRadius: "26px",
            }}
          >
            <Box
              sx={{
                display: { xs: "block", md: "flex", sm: "flex", lg: "flex" },
                justifyContent: "space-between",
              }}
              py={5}
            >
              <FormGroup sx={{ width: { sm: "80%", md: "40%" } }} >
                <FormLabel sx={{ color: "black" }}>First Name</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. James"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                ></Input>
              </FormGroup>

              <FormGroup sx={{ width: { sm: "80%", md: "40%" } }}>
                <FormLabel sx={{ color: "black" }}>Last Name</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. Anderson"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                ></Input>
              </FormGroup>
            </Box>

            <Box
              sx={{
                display: { xs: "block", md: "flex", sm: "flex", lg: "flex" },
                justifyContent: "space-between",
              }}
              pb={5}
            >
              <FormGroup sx={{ width: { sm: "80%", md: "40%" } }}>
                <FormLabel style={{ color: "black" }}>E-Mail</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. James@gmail.com"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                ></Input>
              </FormGroup>
              <FormGroup sx={{ width: { sm: "80%", md: "40%" } }}>
                <FormLabel style={{ color: "black" }}>Phone</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. 087482445"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                ></Input>
              </FormGroup>
            </Box>

            <FormLabel style={{ color: "black" }}>Department</FormLabel>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "60%" },
              }}
              noValidate
              autoComplete="off"
              pt={3}
            >
             
              <TextField
                select
                defaultValue="EUR"
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                color="secondary"
              >
                {aboutUs.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>
            <Box pt={5} pb={1}>
              <Button
                sx={{
                  border: "1px solid #DD952B",
                  borderRadius: "20px",
                  fontWeight: "600",
                }}
                startIcon={<FileUploadIcon />}
                variant="outlined"
                color="secondary"
                size="large"
              >
                Resume
              </Button>
            </Box>

            <Typography pb={2} fontSize={14} color="grey">
              Max File size 2.5 MB
            </Typography>

            <Typography pb={2} fontSize={16}>
            Or send your resume to
              <b style={{ color: "blue" }}> sales@klues.co.in</b>
            </Typography>

            <FormLabel sx={{color:'black'}}>Your Message</FormLabel>

            <FormGroup>
              <Input
                color="secondary"
                placeholder="Tell us something about yourself"
                sx={{
                  borderBottom: "1px solid black",
                  margin:"15px 0px"
                }}
              />
            </FormGroup>
            <Box py={3}>
              <Button
                sx={{
                  border: "1px solid #DD952B",
                  borderRadius: "10px",
                  fontWeight: "600",
                }}
                endIcon={<TrendingFlatIcon />}
                variant="outlined"
                color="secondary"
                size="large"
              >
                Submit
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CvForm;
