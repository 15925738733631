import service1 from "../asset/images/Talentpage/serviceTalent.png";
import service2 from "../asset/images/Talentpage/staffingService.png";
import service3 from "../asset/images/Talentpage/hrConsultingService.png";
import service4 from "../asset/images/Talentpage/diversityService.png";
import skill1 from "../asset/images/skillBuilding/tech1.png";
import skill2 from "../asset/images/skillBuilding/tech2.png";
import skill3 from "../asset/images/skillBuilding/soft1.png";
import skill4 from "../asset/images/skillBuilding/soft2.png";
import skill5 from "../asset/images/skillBuilding/upskill1.png";
import skill6 from "../asset/images/skillBuilding/upskill2.png";
import skill7 from "../asset/images/skillBuilding/industry1.png";
import skill8 from "../asset/images/skillBuilding/industry2.png";

export const talentService = [
  {
    id: 1,
    img1: service1,
    heading1: "Executive Search",
    subText1:
      "Involves identifying and recruiting top-level candidates for leadership positions within an organization. This process requires a deep understanding of the industry, a wide network of contacts, and the ability to assess and attract high-potential individuals. By leveraging these three key elements, executive search firms help companies find the best talent to drive their success.",
    point1:[
      "Clearly articulate the job requirements and expectations for the senior-level positions.",
      "Implement a multi-faceted search strategy to attract top-tier executive talent.",
      "Utilize a rigorous selection process to assess candidate's qualifications and fit for the role.",
    ],
    img2: service2,
    heading2: "Mass Hiring Solutions",
    subText2:
      "Our Mass Hiring Solutions provide scalable recruitment options to effectively meet bulk hiring needs while maintaining quality and compatibility of applicants. With our services, you can streamline the hiring process, ensure consistency in candidate selection, and expedite the onboarding of new employees.",
    point2: [
      "Implement scalable recruitment solutions to efficiently fulfill bulk hiring requirements.",
      "Rigorous screening criteria, assessments, and interviews to ensure that every hire meets your organization's standards and expectations.",
      "Quick and efficient recruitment process to handle large volume of candidates without sacrificing the quality of hires.",
    ],
  },
  {
    id: 2,
    img1: service3,
    heading1: "Specialized Talent Identification ",
    subText1:
      "Klues' talent identification process goes beyond generic criteria to pinpoint individuals with the exact expertise needed to excel in niche domains. Focusing on specialized skills, we ensure a customized approach to acquire talent that delivers exceptional results.",
      point1: [
      "Talent sourcing for diverse industries and specialized domains, meeting unique client requirements.",
      "Personalized approach to sourcing, catering to the specific needs and preferences of each client.",
      "Expert consultation throughout the process, offering strategic guidance and insights to optimize recruitment outcomes.",
    ],
    img2: service4,
    heading2: "Customized Recruitment",
    subText2:
      "Our Customized Recruitment Services offer tailored campaigns designed to fit your company's unique culture and requirements, ensuring optimal candidate attraction and engagement. We specialize in crafting personalized strategies that align with your organization's values and preferences, maximizing the effectiveness of the recruitment process and securing top talent that perfectly matches your needs and vision.",
      point2: [
      "Tailored campaigns to match organizational cultures and preferences.",
      "Providing tailored interactions from initial contact to onboarding, respecting individual preferences and needs. ",
      "Crafting branding strategies that showcase unique company culture and values to attract suitable candidates. ",
    ],
  },
];

export const skillBuilding = [
  {
    id: 1,
    img1: skill1,
    heading: "Technical Skill Development",
    text1:
      "Our Technical Skill Development programs provide thorough training in various industries, equipping participants with the latest tools and methods. We ensure learners receive comprehensive instruction tailored to their needs, empowering them to excel in their respective fields. Through hands-on learning experiences, we foster practical understanding and prepare individuals to thrive in today's dynamic work places.",
    img2: skill2,
    points: [
      "Acquire skills applicable across diverse sectors, ensuring adaptability and relevance.",
      "Stay ahead with training on cutting-edge tools and methodologies, maximizing efficiency and productivity.",
      "Hands-on learning experiences that translate theory into practice, facilitating deeper understanding and confidence in real-world scenarios.",
    ],
  },
  {
    id: 2,
    img1: skill3,
    heading: "Soft Skill Enhancement",
    text1:
      "Elevate your professional capabilities with our Soft Skill training workshops. We focus on honing vital skills like communication, leadership, teamwork, and problem-solving, essential for career advancement. Through targeted guidance and interactive sessions, we empower individuals to thrive in diverse workplace environments and confidently tackle challenges. Invest in your organization’s personal growth and unlock your workforce’s full potential with our tailored soft skill development programs.",
    img2: skill4,
    points: [
      "Develop crucial soft skills, vital for career progression and success of your workforce retention",
      "Master communication, leadership, and teamwork skills to foster positive relationships and drive collective achievement in the workplace.",
      "Acquire the ability to identify and address challenges effectively, enhancing capacity of your pupils to innovate and adapt in dynamic professional environments.",
    ],
  },
  {
    id: 3,
    img1: skill5,
    heading: "Upskilling Program",
    text1:
      "At Klues, we understand that true leadership stems from within. Our upskilling programs are meticulously crafted to empower individuals with the essential qualities that drive impactful leadership. Through a blend of self-reflection, skill-building, and visionary thinking, participants embark on a transformative journey to unlock their full potential.",
    img2: skill6,
    points: [
      "Learn the art of establishing win-win business agreements that foster enduring relationships.",
      "Enhance the ability to work effectively with others through results-oriented versatility skills.",
      "Equips individuals with the tools to set ambitious yet attainable goals, fostering a mindset geared towards long-term success.",
    ],
  },
  {
    id: 4,
    img1: skill7,
    heading: "Industry Specific Training Modules",
    text1:
      "Our specialized training modules are meticulously designed to meet the unique requirements and regulations of diverse industries. Participants gain practical knowledge and insights tailored to their specific sector, enabling them to navigate industry challenges with confidence. By focusing on sector-specific content, we ensure that participants acquire relevant skills and expertise necessary for success in their respective fields.",
    img2: skill8,
    points: [
      "Acquire insights relevant to your industry, enhancing efficiency.",
      "Gain practical knowledge aligned with industry regulations for enhanced compliance.",
      "Navigate sector-specific challenges with confidence and expertise.",
    ],
  },
];
