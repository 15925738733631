import React from "react";
import Layout from "../component/Layout";
import SubHeader from "../component/SubHeader";
import { Box } from "@mui/material";
import { HomesubHead } from "../data/SubHeaderData";
import { Container } from "@mui/material";  
import WhyKlueCard from "../component/WhyKlueCard";
import KluesPerks from "../component/KluesPerks";
import ServiceCard from "../component/ServiceCard";
import bottamHome from "../asset/images/bottamBnrImgs/bottamHome.png";
import Tabsmall from "../component/Tabsmall";

const Home = () => {
  return (
    <Layout>
      
      <Container
        sx={{ maxWidth: { xl: "xl" } }}
      >
        <Box sx={{ mt:15}}>
          <SubHeader isbtn={true} data={HomesubHead} />
        </Box>

        <Box py={5}>
          <Tabsmall/>
        </Box>

        <Box py={5}>
          <WhyKlueCard />
        </Box>

        <Box py={5}>
          <KluesPerks />
        </Box>

        <Box py={5}>
          <ServiceCard />
        </Box>

        <Box sx={{ textAlign: "center" }} py={5}>
          <img src={bottamHome} width="100%" />
        </Box>
      </Container>
    </Layout>
  );
};

export default Home;
