import img1 from "../asset/images/about/values/1.png";
import img2 from "../asset/images/about/values/2.png";
import img3 from "../asset/images/about/values/3.png";
import img4 from "../asset/images/about/values/4.png";
import img5 from "../asset/images/about/values/5.png";
import img6 from "../asset/images/about/values/6.png";


export const cardData = [
    {
      id: 1,
      text: "Integrity",
      Number: "01",
      content1: [
        "Integrity lies at the heart of our organization. ",
        "We uphold the highest ethical standards in all our interactions, prioritizing honesty, transparency, and safeguard data.",
      ],
    },
    {
      id: 2,
      text: "Empowerment",
      Number: "02",
      content1: [
        "Our commitment to empowerment fosters a culture of growth & resilience.",
        "We believe in empowering individuals to reach their full potential by providing them with the resources, support, and opportunities.",
      ],
    },
    {
      id: 3,
      text: "Customer-Centric",
      Number: "03",
      content1: [
        "Being customer-centric is a core value that guides everything we do.",
        "We prioritize understanding and meeting the unique needs and objectives of our clients, ensuring their success and satisfaction.",
      ],
    },
    {
      id: 4,
      text: "Resilience",
      Number: "04",
      content1: ["Resilience empowers us to navigate uncertainties and embrace changes.",
       "With resilience as our foundation, we continuously evolve, learn, and grow, ensuring sustained success for our clients."
      ],
    },
    {
      id: 5,
      text: "Innovation",
      Number: "05",
      content1: [
        "Innovation is the cornerstone of our organization to stay ahead of the curve.",
        "We constantly strive to push boundaries, challenge the status quo, and explore new ideas to enhance our services.",
      ],
    },
    {
      id: 6,
      text: "Array",
      Number: "06",
      content1: [
        "We embrace a diverse array of perspectives, background and talents.",
        "Our commitment to assortment ensures that we attract top talent, cultivate a culture of inclusivity and diversity.",
      ],
    },
  ];

  export const valueData = [
    {
      id: "1",
      img: img1,
      title: "Integrity",
      des: "Integrity forms the cornerstone of our operations.We uphold the highest ethical standards in all our interactions, fostering trust and transparency.​",
    },
    {
      id: "2",
      img: img2,
      title: "Empowerment",
      des: "We empower individuals to reach their full potential by connecting them with opportunities that align with their skills, goals and aspirations",
    },
    {
      id: "3",
      img: img3,
      title: "Customer-Centric",
      des: "Our company Is dedicated to prioritizing customer well-being, ensuring our offerings exceed expectations through comprehensive understanding of aspirations and desires.​",
    },
    {
      id: "4",
      img: img4,
      title: "Resilience",
      des: "In response to rapid market shifts, we maintain agility and adaptability, promptly meeting evolving client requirements.​",
    },
    {
      id: "5",
      img: img5,
      title: "Innovation",
      des: "We prioritize innovation to anticipate developments, delivering advanced solutions that empower clients and job seekers to excel.​",
    },
    {
      id: "6",
      img: img6,
      title: "Assortment",
      des: "We embrace diversity, fostering inclusivity to harness unique contributions, recognizing varied perspectives enhance collective success.​",
    },
  ];