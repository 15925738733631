import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Servicesdata, trainingData } from "../data/servicesdata";
import { HiArrowLongRight } from "react-icons/hi2";
import star from "../asset/images/home/star.png";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";


const ServiceCard = () => {
  return (
    <>
      <div>
        <Grid container>
          <Grid item xs={12} md={12} sm={12} lg={4}>
            <Typography
              sx={{ fontSize: { lg: 40, md: 32, xs: 20 }, fontWeight: "bold" }}
            >
              Recruitment Services
            </Typography>

            <Typography sx={{ fontSize:{lg:16,xs:14}, color: "grey" }}>
              Discover a Suite of Tailored Solutions Designed to Elevate Your
              Business
            </Typography>

            <Typography
              sx={{
                display: { xs: "none", lg: "block" },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center",py:2 }}>
                <Typography
                  sx={{ fontSize: { lg: 20, xs: 18 }, paddingRight: "6px",textDecoration:"none"}}
                  color='primary'
                  component="a"
                  href="/recruitment-services"
                  target="_blank"
                >
                  Learn More
                </Typography>
                <EastOutlinedIcon color="primary" />
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid container>
              {Servicesdata.map((item) => (
                <>
                  <Grid xs={12} sm={6}>
                    <Box
                      bgcolor={"#ebedf5"}
                      borderRadius={5}
                      padding={2}
                      sx={{
                        "&:hover": {
                          background: "#4672E3",
                          color:"white"
                        },
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <img src={star} />
                      <Typography
                        sx={{
                          fontSize: { md: 16, xs: 14 },
                          pl: 1,
                          fontWeight: "500",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    <br />
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          sx={{
            display: { xs: "block", md: "none", lg: "none" },
          }}
        >
           <Typography
            sx={{
              textAlign: "center",
            }}
          >
            <Button variant="text" size="large" endIcon={<HiArrowLongRight />}
            textDecoration="none"
             component="a"
             href="/recruitment-services"
             target="_blank">
              Learn More
            </Button>
          </Typography>
        </Grid>
      </div>

      <div>
        <Grid container pt={6}>
          <Grid item xs={12} md={12} sm={12} lg={4}>
            <Typography
              sx={{ fontSize: { lg: 40, md: 32, xs: 20 }, fontWeight: "bold" }}
            >
              Training Services
            </Typography>

            <Typography sx={{ fontSize:{lg:16,xs:14}, color: "grey" }}>
            Discover comprehensive training programs tailored to elevate your skill set and career prospects.
            </Typography>

            <Typography
              sx={{
                display: { xs: "none", lg: "block" },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center",py:2 }}>
                <Typography
                  sx={{ fontSize: { lg: 20, xs: 18 }, paddingRight: "6px",textDecoration:"none"}}
                  color='primary'
                  component="a"
                  href="/training-services"
                  target="_blank"
                >
                  Learn More
                </Typography>
                <EastOutlinedIcon color="primary" />
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid container>
              {trainingData.map((item) => (
                <>
                  <Grid xs={12} sm={6}>
                    <Box
                      bgcolor={"#ebedf5"}
                      borderRadius={5}
                      padding={2}
                      sx={{
                        "&:hover": {
                          background: "#4672E3",
                          color:"white"
                        },
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <img src={star} />
                      <Typography
                        sx={{
                          fontSize: { md: 16, xs: 14 },
                          pl: 1,
                          fontWeight: "500",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    <br />
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          sx={{
            display: { xs: "block", md: "none", lg: "none" },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            <Button variant="text" size="large" endIcon={<HiArrowLongRight />}
            textDecoration="none"
             component="a"
             href="/training-services"
             target="_blank">
              Learn More
            </Button>
          </Typography>
        </Grid>
      </div>

    
    </>
  );
};

export default ServiceCard;
