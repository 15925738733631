import {
  Typography,
  Grid,
  Box,
  Button,
  MenuItem,
  Divider,
  Stack,
} from "@mui/material";
import React, { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import testinomial from "../asset/images/footerImgs/test1.png";
import { HiArrowLongRight } from "react-icons/hi2";
import logo from "../asset/images/footerImgs/f-logo.png";
import Carousel from "react-material-ui-carousel";
import { testData } from "../data/testinomialData";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import FI from "../asset/images/footerimage.png";

const Footer = () => {
  const carouselRef = useRef(null);

  const handleScrollToBottom = () => {
    if (carouselRef.current) {
      scroll.scrollToBottom({
        containerId: carouselRef.current.carouselId,
        duration: 1000,
      });
    }
  };

  const handleChange = (index) => {
    if (index === testData.length - 3) {
      console.log("Reached the bottom!");
    }
  };
  const aboutUs = [
    {
      value: "Empty",
      label: "None",
    },
    {
      value: "google",
      label: "google search",
    },
    {
      value: "Referral",
      label: "Friends Referral",
    },
    {
      value: "media",
      label: "social media",
    },
  ];
  return (
    <div>
      <Box
        sx={{
          bgcolor: "#4672E3",
          color: "white",
          fontFamily: "rubic",
          borderRadius: "35px 35px 0px 0px",
        }}
        py={5}
        px={3}
      >
        <Grid container spacing={4} px={2} pt={4} justifyContent="space-evenly">
          <Grid item xl={6} lg={6} md={7} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }} pb={1.5}>
              <Typography sx={{ fontSize: { xs: 18 } }} pr={1}>
                Business Inquiries
              </Typography>
              <HiArrowLongRight />
            </Box>
            <Typography
              sx={{ fontSize: { lg: 34, md: 28, xs: 20 }, fontWeight: "bold" }}
            >
              Need help with hiring? Contact us below. We look forward to
              hearing from you!
            </Typography>
            <Box
              sx={{
                display: { xs: "block", md: "flex", sm: "flex", lg: "flex" },
                justifyContent: "space-between",
              }}
              width={"60%"}
            >
              <TextField
                id="standard-basic"
                label="First Name"
                variant="standard"
                color="success"
                placeholder="e.g. James"
              />
              <TextField
                id="standard-basic"
                label="Last Name"
                variant="standard"
                color="success"
                placeholder="e.g. Anderson"
              />
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "flex", sm: "flex", lg: "flex" },
                justifyContent: "space-between",
              }}
              width={"60%"}
              pt={2}
            >
              <TextField
                label="E-Mail"
                variant="standard"
                color="success"
                placeholder="e.g. James@gmail.com"
              />
              <TextField
                label="Phone"
                variant="standard"
                color="success"
                placeholder="e.g. 087482445"
              />
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { width: "50%" },
              }}
              pt={3}
            >
              <TextField
                select
                label="How did you hear about us"
                defaultValue="None"
                color="success"
                variant="standard"
              >
                {aboutUs.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Typography sx={{ fontSize: 16 }} py={2}>
              By sending this I confirm that I have read and accept the{" "}
              <b>privacy policy</b>
            </Typography>{" "}
            <Box py={2}>
              <Button
                sx={{
                  borderRadius: "20px",
                  fontWeight: "bold",
                }}
                endIcon={<HiArrowLongRight />}
                variant="contained"
                color="success"
                size="medium"
              >
                Submit
              </Button>
            </Box>
          </Grid>

          <Grid item xl={5} lg={6} md={5} sm={12} sx={12}>
            <Box pb={4}>
              <img src={FI} width="100%" height="500px" />
            </Box>
            {/* <Carousel
              direction="vertical"
              ref={carouselRef}
              onChange={handleChange}
              autoPlay
              infiniteLoop
              showStatus={false}
              showThumbs={false}
            >
              {testData.map((item, index) => (
                <Box
                  key={index}
                  textAlign="justify"
                  p={3}
                  mb={4}
                  mx={2}
                  sx={{
                    bgcolor: "white",
                    borderRadius: 10,
                  }}
                >
                  <Box px={4} py={2} textAlign="center">
                    <Typography
                      color="primary"
                      sx={{ fontSize: { md: 16, xs: 14 }, fontWeight: "bold" }}
                    >
                      {item.description}
                    </Typography>
                    <Box
                      component="img"
                      src={item.image}
                      alt="testImg"
                      width="100px"
                      pt={3}
                      pb={2}
                    />
                    <Typography
                      color="primary"
                      sx={{ fontSize: { lg: 18, xs: 16 } }}
                    >
                      <b>{item.name}</b>
                    </Typography>
                    <Typography
                      sx={{ fontSize: { md: 16, xs: 14 } }}
                      color="black"
                      py={2}
                    >
                      {item.designation}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Carousel> */}
          </Grid>
        </Grid>

        <Divider color="lightgrey" />

        <Box pt={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5} md={5} sm={4}>
              <Box component="img" src={logo} alt="f-logo" width="165px" />
              {/* <Typography
                pt={2}
                color="lightgrey"
                fontWeight="bold"
                sx={{ fontSize: 18 }}
              >
                conventional role of a recruitment agency
              </Typography> */}
              <Stack direction="row" spacing={3} py={2}>
                <a
                  href="https://www.instagram.com/klues_recruitment_services/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {<InstagramIcon sx={{ color: "white", fontSize: "25px" }} />}
                </a>
                <a
                  href="https://www.linkedin.com/company/kluesservices/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {<LinkedInIcon sx={{ color: "white", fontSize: "25px" }} />}
                </a>
                <a
                  href="mailTo:sales@klues.co.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {<EmailIcon sx={{ color: "white", fontSize: "25px" }} />}
                </a>
                <a
                  href="tel:sales@klues.co.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {<CallIcon sx={{ color: "white", fontSize: "25px" }} />}
                </a>
              </Stack>
            </Grid>
            <Grid item xs={6} lg={2} md={2} sm={3} color="lightgrey">
              <Typography fontSize={20}>
                <b style={{ color: "white" }}>Quick Links</b>
              </Typography>
              <Stack spacing={1} py={2}>
                <Typography
                  component={Link}
                  to={"/about-us"}
                  target="_self"
                  sx={{ textDecoration: "none", color: "common.grey" }}
                >
                  About Us
                </Typography>
                <Typography
                  component={Link}
                  target="_self"
                  to={"/browse-job"}
                  sx={{ textDecoration: "none", color: "common.grey" }}
                >
                  Browse Jobs
                </Typography>
                <Typography
                  component={Link}
                  target="_self"
                  to={"/submit-cv"}
                  sx={{ textDecoration: "none", color: "common.grey" }}
                >
                  Submit Your CV
                </Typography>
                <Typography
                  component={Link}
                  target="_self"
                  to={"/refer-friend"}
                  sx={{ textDecoration: "none", color: "common.grey" }}
                >
                  Refer Your Friend
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} lg={2} md={2} sm={2} color="lightgrey">
              <Typography fontSize={20}>
                <b style={{ color: "white" }}>Services</b>
              </Typography>
              <Stack spacing={1} py={2}>
                <Typography
                  component={Link}
                  target="_self"
                  to={"/recruitment-services"}
                  sx={{ textDecoration: "none", color: "common.grey" }}
                >
                  Recruitment Services
                </Typography>
                <Typography
                  component={Link}
                  target="_self"
                  to={"/training-services"}
                  sx={{ textDecoration: "none", color: "common.grey" }}
                >
                  Training Services
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2} md={2} sm={3} color="lightgrey">
              <Typography fontSize={20}>
                <b style={{ color: "white" }}>Office</b>
              </Typography>
              <Stack spacing={1} py={2}>
                <Typography>Greater Noida</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <Typography fontSize={16} textAlign="center">
                @2022{" "}
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </Link>
                {" / "}
                <Link
                  to="/terms-conditions"
                  target="_blank"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Terms of Use
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;

