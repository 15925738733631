import { Box, Typography } from "@mui/material";
import React from "react";
import LearnMoreBtn from "./LearnMoreBtn";
import { TypeAnimation } from "react-type-animation";

const SubHeader = ({ data, isbtn = false }) => {
  // console.log("data",data.firsthead)
  return (
    <>
      <Box sx={{ py: 4 }}>
        <Box>
          <Typography
            sx={{
              fontSize: { lg: 55, md: 45, sm: 30 },
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            <TypeAnimation
              sequence={[`${data.firsthead} ${data.highhead} `, 3000]}
              speed={50}
              style={{ color: "#DD952B" }}
              repeat={Infinity}
            ></TypeAnimation>
          </Typography>

          <Typography
            color="grey"
            sx={{
              px:{sm:10, xs:0},
              fontSize: { lg: 22, md: 20, xs: 18 },
              pt: 5,
              textAlign: "center",
            }}
          >
            {data.bottamText}
          </Typography>
        </Box>

        {isbtn && (
          <Box sx={{ textAlign: "center" }} pt={5}>
            <LearnMoreBtn />
          </Box>
        )}
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <img src={data.headerImg} width="100%" />
      </Box>
    </>
  );
};

export default SubHeader;
