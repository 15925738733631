 import img1 from "../asset/images/home/whykluesicon/Expertise.png"
 import img2 from "../asset/images/home/whykluesicon/TailoredSol.png"
 import img3 from "../asset/images/home/whykluesicon/ExtensiveNet.png"
 import img4 from "../asset/images/home/whykluesicon/Innovation.png"
 import img5 from "../asset/images/home/whykluesicon/Commitment.png"
 import img6 from "../asset/images/home/whykluesicon/Enduring.png"
 import img7 from "../asset/images/home/whykluesicon/Transperent.png"
 import img8 from "../asset/images/home/whykluesicon/Betterout.png"
 
 
 export const whykluedata = [
    {
      id: "01",
      image: img1,
      text: "Personalized Service",
      content:"Customized recruitment solutions and training programs to align with each client's unique goals and requirements.",
    },
    {
      id: "02",
      image: img2,
      text: "Analysis",
      content: "In-depth analysis of organizational recruitment and training needs & objectives, including skills gaps & challenges.",
    },
    {
      id: "03",
      image: img3,
      text: "Innovation",
      content: "We consistently stay ahead by providing inventive ideas and solutions for achieving success in a rapidly evolving market.",
    },
    {
      id: "04",
      image: img4,
      text: "Commitment to Quality",
      content: "Quality is our priority throughout every step from exceptional candidate sourcing to unparalleled client support.",
    },
    {
      id: "05",
      image: img5,
      text: "Enduring Partnerships",
      content: "We transcend mere service provider; rather, we function as your strategic ally, fully committed to ensure enduring prosperity.",
    },
    {
      id: "06",
      image: img6,
      text: "Transparent Communication",
      content: "Anticipate transparent and open communication at every stage, fostering trustworthiness and accountability.",
    },
    {
      id: "07",
      image: img7,
      text: "Extensive Network",
      content: "Our widespread grid connects you with top-notch talent and promising opportunities to create mutuall  partnerships.",
    },
    {
      id: "08",
      image: img8,
      text: "Better Outcomes",
      content: "We pursue outstanding results in every interaction, be it in recruitment or training with unmatched outcomes.",
    }
]
