import { createTheme } from "@mui/material/styles";
import '@fontsource/rubik/300.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/600.css';
import '@fontsource/rubik/700.css';
const BaseTheme = createTheme({
  palette: {
    primary: {
      main: "#4672E3",
      blue:"#4F6EB5",
      hover: "#E9EFFF",
    },
    secondary: {
      main: "#DD952B",
    },
    success: {
      main: "#FFFFFF",
    },
    common: {
      black: "#000000",
      white: "#FFFFFF",
      grey: "#DCD9D9",
      lightgrey: "#DCD9D9",
    },
  },
  typography: {
    fontFamily: {
      main: "rubik",
    },
  },
});

export default BaseTheme;
