import React from "react";
import Layout from "../component/Layout";
import { Box, Container } from "@mui/material";
import SubHeader from "../component/SubHeader";
import { Unleashing } from "../data/SubHeaderData";
import AboutCard from "../component/AboutCard";
import ValueCard from "../component/ValueCard";


const AboutUs = () => {
  return (
    <div>
      <Layout>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Box sx={{ mt:15}}>
            <SubHeader data={Unleashing} />
          </Box>

          <Box py={5}>
            <ValueCard/>
          </Box>

          <Box>
            <AboutCard/>
          </Box>
        </Container>
      </Layout>
    </div>
  );
};

export default AboutUs;
