import bnrHome from "../asset/images/bannerImges/bnrHome.png";
import BrowsJobBnr from "../asset/images/bannerImges/BrowsJobBnr.png";
import myCvBnr from "../asset/images/bannerImges/myCvBnr.png";
import referBnr from "../asset/images/bannerImges/referBnr.png";
import talentBnr from "../asset/images/bannerImges/talentBnr.png";
import skillBnr from "../asset/images/bannerImges/skillBnr.png";
import aboutBnr from "../asset/images/bannerImges/aboutbnr.png";

export const HomesubHead = {
  firsthead: "Elevate your Workforce with",
  highhead: "Premier Recruitment and Training Solutions",
  bottamText:
    "We are your dedicated partner in building high-performing teams and nurturing individual potential for sustained success.",
  headerImg: bnrHome,
};

export const BrowsSubhead = {
  firsthead: "Discover a world of endless opportunities and",
  highhead: "growth potential with Klues",
  bottamText:
    "Explore our diverse array of career opportunities tailored to match your skills and aspirations. Browse through our curated selection of jobs and take the first step towards your dream career today!​",
  headerImg: BrowsJobBnr,
};

export const UnlockData = {
  firsthead: "Submit Your Resume and Ready to",
  highhead: "Soar New Heights",
  bottamText: "Seamlessly Apply with Confidence and Let Your CV Speak Volumes",
  headerImg: myCvBnr,
};

export const Double = {
  firsthead: "Help Us Catch A Rising Star ! ",
  highhead: "Refer A Friend",
  bottamText: "Share the success, watch the success and boost your Network",
  headerImg: referBnr,
};

export const TrainingSubHead = {
  firsthead: "Expand Your Workforce Horizons & Boost Efficiency with",
  highhead: "our Inclusive Training Solutions",
  bottamText:
    "Personalized training tailored according to the needs, ensuring the greatest positive effect on your skills and abilities. Our customized approach guarantees training that directly addresses your specific goals for maximum impact and effectiveness.",
  headerImg: skillBnr,
};

export const Discover = {
  firsthead: "Discover a Suite of",
  highhead: "Tailored Solution Designed to Elevate Your Business",
  bottamText:
    "Empowering individuals for future success by providing career opportunities and support in reaching their goals. Our focus is on building pathways for seekers to thrive in their chosen careers through guidance and resources.",
  headerImg: talentBnr,
};

export const Unleashing = {
  firsthead: "Unleashing Potential Through",
  highhead: "Our Recruitment & Skill Training",
  bottamText:
    "Empowering the future and building career opportunity for the seekers.",
  headerImg: aboutBnr,
};
